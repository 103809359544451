let hasLoadedMaterialSymbolsFont = false;
/**
 * Loads local fonts.
 */
export default function loadFonts() {
    if (hasLoadedMaterialSymbolsFont) {
        return;
    }

    hasLoadedMaterialSymbolsFont = true;

    const fontSheet = new CSSStyleSheet();

    fontSheet.replaceSync(`
    @font-face {
        font-family: "Open Sauce Sans";
        src: url("https://unpkg.com/@polymarket/embeds@latest/dist/fonts/OpenSauceSans-Regular.ttf") format("truetype");
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: "Open Sauce Sans";
        src: url("https://unpkg.com/@polymarket/embeds@latest/dist/fonts/OpenSauceSans-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "Open Sauce Sans";
        src: url("https://unpkg.com/@polymarket/embeds@latest/dist/fonts/OpenSauceSans-SemiBold.ttf") format("truetype");
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: "Open Sauce Sans";
        src: url("https://unpkg.com/@polymarket/embeds@latest/dist/fonts/OpenSauceSans-Bold.ttf") format("truetype");
        font-weight: 700;
        font-style: normal;
    }`);

    document.adoptedStyleSheets.push(fontSheet);
};
